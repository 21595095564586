import "./set-public-path";
import { createApp, h } from "vue";
import singleSpaVue from "single-spa-vue";
import App from "./App.vue";

const vueLifecycles = singleSpaVue({
	createApp,
	appOptions: {
		// @ts-ignore
		render() {
			return h(App, {
				props: {
					// single-spa props are available on the "this" object. Forward them to your component as needed.
					// https://single-spa.js.org/docs/building-applications#lifecyle-props

					// @ts-ignore
					name: this.name,
					// @ts-ignore
					mountParcel: this.mountParcel,
					// @ts-ignore
					singleSpa: this.singleSpa
				}
			});
		}
	}
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;