
import { defineComponent } from "vue";

interface Model {
}

export default defineComponent({
	name: "App",
	components: {},
	data(): Partial<Model> {
		return {};
	},

	methods: {
		goBack() {
			history.back();
		}
	}
});

